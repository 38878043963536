const tagExclusion = {};

const tagSystem = {
  'cvent.steps.boxes': {
    'cvent.cards.second_step.title': {
      key: 'Training',
      value: 'Device training',
    },
  },
  'cvent.steps.se_representative': {
    'cvent.steps.training_pump': {
      key: 'Training',
      value: 'Pump',
    },
    'cvent.steps.training_smartGuard': {
      key: 'Training',
      value: 'SmartGuard',
    },
    'cvent.steps.training_carelink': {
      key: 'Training',
      value: 'CareLink',
    },
    'cvent.steps.oppfriskningskurs': {
      key: 'Training',
      value: 'Oppfriskning',
    },
    'cvent.steps.sensorGuardian4': {
      key: 'Training',
      value: 'Guardian 4',
    },
    // 'cvent.steps.training_smartPen': {
    //   key: 'Training',
    //   value: 'Smart Pen',
    // },
  },
  'cvent.steps.medtronic_age_question': {
    'cvent.steps.cvent_check_if_age_under_17': {
      key: 'Age',
      value: 'Child',
    },
    'cvent.steps.cvent_check_if_age_above_18': {
      key: 'Age',
      value: 'Adult',
    },
  },
};

export function flow(summary) {
  const finalTags = [];
  const filtered = [];

  for (const userTags of summary) {
    const stepNamesToRemove = tagExclusion[userTags.option.title] || [];

    if (stepNamesToRemove) {
      for (const stepName of stepNamesToRemove) {
        filtered.push(stepName);
      }
    }
  }
  const chosenTags = summary
    .filter((step) => {
      for (const exclusion of filtered) {
        if (exclusion === step.stepName) {
          return false;
        }
      }
      return true;
    })
    .map((choice) => {
      if (tagSystem[choice.stepName]) {
        return tagSystem[choice.stepName][choice.option.title];
      }
      return undefined;
    });
  for (const choice of chosenTags) {
    if (choice !== undefined) {
      finalTags.push(choice);
    }
  }
  return finalTags;
}
